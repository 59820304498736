import { DEFAULT_LIBRARY_SORT_FILTER, LIBRARY_SORT_FILTER } from 'constants/apiConstants';
import { getOrGenerateGaOptimizeCookie, removeGaOptimizeCookie } from 'storage/cookies/optimizeCookie';
import cookieStorage from 'storage/cookies/utility/cookies';
import { isSSR } from 'utility/isSSR';
import uaParser from 'utility/uaParser';
import { FEATURE_TOGGLER } from './config';

export type ExtendedConfig = ReturnType<typeof getExtendedConfig>;

// Models
// eCommerce: APP_TYPE=LIBRARY & HISTORY_TYPE=BROWSER (NG)
// library: APP_TYPE=LIBRARY & HISTORY_TYPE=HASH (AMI, BH&G)
// newsstand: APP_TYPE=NEWSSTAND & HISTORY_TYPE=BROWSER (ZU, Zinio)
// reader: APP_TYPE=READER & HISTORY_TYPE=HASH

// Features
// search: eCommerce, Newsstand
// language: if > language (> 1 newsstand)
// reader type: Magazine or Article
// explore: APP_TYPE = NEWSSTAND
// shop button: HISTORY_TYPE=BROWSER (ecommerce or newsstand)
// shop menu: APP_TYPE=NEWSSTAND
// cart:  HISTORY_TYPE=BROWSER (ecommerce or newsstand)
// account settings: HISTORY_TYPE=BROWSER (ecommerce or newsstand)
// support: if support link is available
// sign-up link: HISTORY_TYPE=BROWSER (ecommerce or newsstand)

export const EXTERNALS_LOGIN_HANDLERS = {
    AUTH0: 'auth0',
    GIGYA: 'gigya',
    OPENID: 'openid',
};

export const isServiceWorkerAvailableInClientSide = (config: State.ConfigType): boolean =>
    config.PWA &&
    !uaParser.isSafari && // DISABLED BECAUSE SPACE LIMITATIONS
    !uaParser.isSafariMobile &&
    'serviceWorker' in navigator;

export const getExtendedConfig = (config: State.ConfigType) => {
    if (!config.newsstands_config) {
        config.newsstands_config = {
            REQUIRES_ADULT_CONFIRMATION: [],
            NOT_EXPLICIT_CONSENT_LIST: [],
        };
    }
    if (!config.links_contact_config) {
        config.links_contact_config = {} as State.LINKS_CONTACT_CONFIG;
    }
    if (!config.themes) {
        config.themes = {};
    }
    if (!config.third_parties) {
        config.third_parties = {};
    }

    /**
     *  We set the cookie value for Google Optimization based on the experiment
     *  that comes from the node server and save it in the state so components
     *  can get it and alter their render based on variant assigned.
     *  If the experiment from the server is not present, the current existing
     *  cookie will be removed, if any.
     *  If the experiment from server differs from the stored in the cookie
     *  the cookie will be replaced with a new one.
     */
    if (!isSSR && cookieStorage.isEnabled()) {
        if (FEATURE_TOGGLER.GOOGLE_OPTIMIZE_EXPERIMENT) {
            config.third_parties.GA_OPTIMIZE_COOKIE = getOrGenerateGaOptimizeCookie(
                config.third_parties.GA_OPTIMIZE_EXPERIMENT_ID,
                config.third_parties.GA_OPTIMIZE_NUM_VARIANTS,
            );
        } else {
            config.third_parties.GA_OPTIMIZE_COOKIE = removeGaOptimizeCookie();
        }
    }

    const isWhitelabelNewsstand =
        config.APP_TYPE === 'NEWSSTAND' && config.PROJECT_ID !== 99 && config.PROJECT_ID !== 88;

    const isAuth0 = config.EXTERNAL_AUTHORIZATION?.EXTERNAL_HANDLER === EXTERNALS_LOGIN_HANDLERS.AUTH0;

    const isGigya = config.EXTERNAL_AUTHORIZATION?.EXTERNAL_HANDLER === EXTERNALS_LOGIN_HANDLERS.GIGYA;

    const isOpenid = config.EXTERNAL_AUTHORIZATION?.EXTERNAL_HANDLER === EXTERNALS_LOGIN_HANDLERS.OPENID;

    // Should filter out if is_force_register is true? Does it needs the sync in this case?
    const needsWhiteWalkerSync = !!config.EXTERNAL_AUTHORIZATION?.EXTERNAL_HANDLER && !isAuth0 && !isOpenid && !isGigya;

    const useBookmarks = config.APP_TYPE !== 'READER';

    const showSmartBannerAndroid =
        config.smartBanner?.showInstallAppsBanner &&
        !!config.links_contact_config.ANDROID_APP &&
        !!config.smartBanner?.androidPackageName &&
        config.smartBanner?.androidCertFingerprints?.length > 0;

    const showSmartBannerIOS =
        config.smartBanner?.showInstallAppsBanner &&
        !!config.links_contact_config.IOS_APP &&
        config.smartBanner?.iOSAppIDs?.length > 0;

    return {
        ...config,
        isWhitelabelNewsstand,
        isPWAEnabled: !isSSR && isServiceWorkerAvailableInClientSide(config),
        useBookmarks,
        useNewsstandCookie: (config.PROJECT_ID === 99 && !config.isCMS) || config.PROJECT_ID === 88,
        voucherFeatureEnabled: config.PROJECT_ID === 88 && FEATURE_TOGGLER.SHOW_VOUCHER_PROMO,
        UI: {
            Layout: getLayoutName(config),
            openDesktopLink: config.isNativefier,
            showBackToHome: config.APP_TYPE !== 'RESET_PASSWORD',
            showSmartBannerAndroid,
            showSmartBannerIOS,
            showCustomPWAPrompt: config.PWA && !(showSmartBannerAndroid || showSmartBannerIOS),
        },
        HEADER: {
            showCart: !config.shopping?.externalPurchaseUrl && (config.isWBA || config.APP_TYPE === 'NEWSSTAND'),
            showShop: config.isWBA || config.APP_TYPE === 'NEWSSTAND',
            showAccountSettings:
                (config.isWBA || config.APP_TYPE === 'NEWSSTAND') &&
                (Object.values(config.show_settings).some((value) => value) || config.follow.followPublicationsEnabled),
            showMagazinesDropdownMenu: config.isWBA || config.APP_TYPE === 'NEWSSTAND',
            showRefreshLibrary: config.isNativefier,
            showMyLibrary: config.HISTORY_TYPE !== 'HASH',
            searchType: config.isWBA ? 'ARTICLE' : config.APP_TYPE === 'NEWSSTAND' ? 'PUBLICATION&ARTICLE' : 'LIBRARY',
            isClickLogoDisabled: config.isNativefier,
            showSupportMenu: !!config.links_contact_config.ZENDESK,
            showContactSetting: (config.isWBA || isWhitelabelNewsstand) && !!config.links_contact_config.CONTACT_EMAIL,
            showLanguageSelector: !isWhitelabelNewsstand, // added this rule to target Aller
            showWhyZinio: config.PROJECT_ID === 99 && !config.isNativefier,
            showFreeMagazines: config.PROJECT_ID === 99,
        },
        LOGIN: {
            enableSignup:
                (config.isWBA || config.APP_TYPE === 'NEWSSTAND' || config.isNativefier) &&
                ((!config.EXTERNAL_AUTHORIZATION?.EXTERNAL_HANDLER && !needsWhiteWalkerSync) || isGigya),
            showWBATermsAndPolicy: config.isWBA,
            needsWhiteWalkerSync,
            isExternalLogin: !!config.EXTERNAL_AUTHORIZATION?.EXTERNAL_HANDLER,
            isAuth0,
            isGigya,
            isOpenid,
        },
        LIBRARY: {
            showLibrarySearch:
                config.APP_TYPE === 'NEWSSTAND' ||
                (config.APP_TYPE === 'LIBRARY' && !config.BRANDED_APP_PUBLICATION_ID),
            showDownloadOverlay: config.PWA,
            shouldOpenInSameTab: config.isNativefier,
            defaultSortedBy: config.isSinglePublication
                ? LIBRARY_SORT_FILTER.PUBLISH_DATE
                : DEFAULT_LIBRARY_SORT_FILTER,
        },
        SERVER: {
            removeXFrame: config.APP_TYPE === 'READER',
            initializeConfig: config.APP_TYPE !== 'READER',
            enableSSRMeta: config.APP_TYPE === 'NEWSSTAND',
        },
        FOOTER: {
            showPoweredBy: config.APP_TYPE !== 'NEWSSTAND' || isWhitelabelNewsstand,
        },
        HOME: {
            showSubBanner: config.PROJECT_ID === 99,
            showSubBannerAYCR: config.PROJECT_ID === 88,
        },
        FEATURE_TOGGLER: {
            ...FEATURE_TOGGLER,
            // enables or disables multicatalog feature
            IS_MULTICATALOG_ENABLED: [
                // 2043, // DPG BE // Out of scope for now
                1122, // DPG NL
            ].includes(config.PROJECT_ID),
            // tells if a project has been configured to be a multicatalog project (for rollback purposes)
            IS_MULTICATALOG_PROJECT: [
                // 2043, // DPG BE // Out of scope for now
                1122, // DPG NL
            ].includes(config.PROJECT_ID),
        },
    };
};

const getLayoutName = (config: State.ConfigType) =>
    config.APP_TYPE === 'NEWSSTAND'
        ? 'Layout'
        : config.isWBA || config.APP_TYPE === 'LIBRARY'
        ? 'LibraryLayout'
        : config.APP_TYPE === 'RESET_PASSWORD'
        ? 'AccountsLayout'
        : null;
